export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['Menú']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Eventos',
        to: '/evento',
        icon: 'cil-calculator'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Mercados',
        to: '/mercado',
        icon: 'cil-calculator'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Selección',
        to: '/seleccion',
        icon: 'cil-calculator'
      }
    ]
  }
]